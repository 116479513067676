<template>
  <b-list-group>
    <b-list-group-item
      v-for="ruta in rutas"
      :key="ruta.id"
      button
      :disabled="false"
      @click="rutaOpen(ruta)"
    >
      {{ ruta.nombre }} - {{ ruta.vehiculo_tipo }}
      <vehiculo-icon
        :color="ruta.color"
        size="36"
        class="ml-1"
      />
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { BListGroup, BListGroupItem } from 'bootstrap-vue'
import VehiculoIcon from '@/assets/images/icons/maps/VehiculoIcon.vue'
// import usePedidos from '@/views/bimbo/pedidos/pedidos-all/usePedidos'
import { computed } from '@vue/composition-api'
import useRiders from './useRiders'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    VehiculoIcon,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // const {
    //   rutaColorIcon,
    // } = usePedidos

    const {
      rutasRider,
      rutasPendientes,
      rutasTerminadas,
      newRuta,
      rutaOpen,
    } = useRiders()

    const rutas = computed(() => (props.status === 'pendientes' ? rutasPendientes.value : rutasTerminadas.value))

    return {
      rutasRider,
      rutasPendientes,
      rutasTerminadas,
      newRuta,
      rutaOpen,
      rutas,
      // rutaColorIcon,
    }
  },
}
</script>
