<template>
  <b-row>
    <b-col
      cols="12"
      class="mt-2"
    >
      <h3>Rutas Pendientes</h3>
      <rutas-list :status="'pendientes'" />
    </b-col>

    <b-col
      cols="12"
      class="mt-2"
    >
      <h3>Rutas Terminadas</h3>
      <rutas-list :status="'terminadas'" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import RutasList from './RutasList.vue'

export default {
  components: {
    BRow,
    BCol,

    RutasList,
  },
}
</script>
